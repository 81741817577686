import React, { useState } from "react";
import "./onlineAds.css";

function OnlineAds() {
  const categories = [
    "Social",
    "Online_Ads",
    "Digital_Films",
    "Animated_Videos",
    "SEO",
    "Ecommerce",
  ];

  const [currentCategory, setCurrentCategory] = useState(categories[0]);

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const renderImages = () => {
    const images = {
      Social: "https://redcubenew.netlify.app/images/tabimg1.png",
      Online_Ads: "https://redcubenew.netlify.app/images/tabimg-2.png",
      Digital_Films: "https://redcubenew.netlify.app/images/tabimg-3.png",
      Animated_Videos: "https://redcubenew.netlify.app/images/tabimg-4.png",
      SEO: "https://redcubenew.netlify.app/images/tabimg-5.png",
      Ecommerce: "https://redcubenew.netlify.app/images/tabimg-3.png",
    };

    return (
      <img
        className="carousel_image"
        src={images[currentCategory]}
        alt={currentCategory}
      />
    );
  };
  return (
    <>
      <section className="slider-images-text">
        <div className="tab-buttons">
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("Social")}
          >
            Social
          </button>
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("Online_Ads")}
          >
            Online Ads
          </button>
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("Digital_Films")}
          >
            Digital Films
          </button>
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("Animated_Videos")}
          >
            Animated Videos
          </button>
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("SEO")}
          >
            SEO
          </button>
          <button
            className="tab_buttons_category"
            onClick={() => handleCategoryChange("Ecommerce")}
          >
            Ecommerce
          </button>
        </div>
        <div className="img-content">
          <div className="slider-image">{renderImages()}</div>
          <div className="slider-image-content">
            <h6 className="online_ads">ONLINE ADS</h6>
            <p className="online_ecosystem">
              In an online ad ecosystem, is it vital to be visible to your
              audience. As a brand, being relevant is your biggest challenge.
            </p>
            <p className="content-para">
              As scale and speed matter and efficiency matters even more every
              day, it is imperative to implement the right insights and online
              ad marketing strategy.
            </p>
            <p className="learn_more_para">
              <span className="learn_more">
                Learn more about our “online ads” service
              </span>
            </p>
            <a href="#link">
              <img
                src="https://redcubenew.netlify.app/images/btnarrow.png"
                alt=""
                className="btnArrow"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default OnlineAds;
