// import React from 'react';
// import './whatWeDo.css';

// function WhatWeDo() {
//   return (
//     <>
//     <div className="brand-text-button">
//         <div className="text">
//           <h3 className="brand-text">
//             We help companies grow by increasing the creative capital in their
//             brands and businesses, with an obsession for real-world results.
//           </h3>
//         </div>
//         <button className="see_what_we_do">see what we do</button>
//       </div>
//     </>
//   )
// }

// export default WhatWeDo

import React from 'react';
import './whatWeDo.css';

function WhatWeDo() {
  return (
    <div className="brand-text-button">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="text">
              <h3 className="brand-text">
                We help companies grow by increasing the creative capital in their
                brands and businesses, with an obsession for real-world results.
              </h3>
            </div>
            <button className="see_what_we_do">see what we do</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
