import React from "react";
import "./banner.css";

function Banner() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col mainBanner">
            <div className="banner-image">
              <img
                className="banner-pic"
                src="https://redcubenew.netlify.app/static/media/banner.71adc9a16543ad068877.png"
                alt=""
              />
              <div className="marketing-container container">
                <img
                  src="https://redcubenew.netlify.app/images/award01.png"
                  alt=""
                  className="marketing-images img-thumbnail"
                />
                <img
                  src="https://redcubenew.netlify.app/images/award02.png"
                  alt=""
                  className="marketing-images img-thumbnail"
                />
                <img
                  src="https://redcubenew.netlify.app/images/award03.png"
                  alt=""
                  className="marketing-images img-thumbnail"
                />
                <img
                  src="https://redcubenew.netlify.app/images/award04.png"
                  alt=""
                  className="marketing-images img-thumbnail"
                />
                <img
                  src="https://redcubenew.netlify.app/images/award05.png"
                  alt=""
                  className="marketing-images img-thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <img
          className="banner-image"
          src="https://redcubenew.netlify.app/static/media/banner.71adc9a16543ad068877.png"
          alt=""
        /> */}
        {/* <div className="marketing-image"> */}
        {/* <div className="marketing-container">
            <img
              src="https://redcubenew.netlify.app/images/award01.png"
              alt=""
              className="marketing-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/award02.png"
              alt=""
              className="marketing-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/award03.png"
              alt=""
              className="marketing-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/award04.png"
              alt=""
              className="marketing-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/award05.png"
              alt=""
              className="marketing-images"
            />
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default Banner;
