import React from "react";
import "./whatOurClientSay.css";

function WhatOurClientSay() {
  return (
    <>
      <section className="what_our_clients_say">
        <h2>What our clients are saying?</h2>
        <div className="client-image-testimonial">
          <div className="image-client">
            <img
              src="https://redcubenew.netlify.app/images/testimonial1.png"
              alt=""
            />
          </div>
          <div className="text-client">
            <p className="testimonial-client">
              We have worked with Redcube for the past few years and have always
              been happy with their communication, their honesty with time
              estimations, their speed with responses and their ability <br />{" "}
              to deliver within the original timeline and budget agreed upon. We
              look forward to many more projects with this winning team of
              development experts.
            </p>
            <h3 className="tom_bergey">Tom Bergey</h3>
            <h4 className="bergey_creative_group">Bergey Creative Group</h4>
            <img
              src="https://redcubenew.netlify.app/images/bergey.png"
              alt=""
            />
          </div>
        </div>
        <div className="other-client-image-text">
          <div className="client-text-image">
            <img
              src="https://redcubenew.netlify.app/images/box1.png"
              alt=""
              className="other-client-image"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <p className="link_other_client">Explore Redcube “Career”</p>
            <a href="#link">
              <img
                href="#"
                src="https://redcubenew.netlify.app/images/btnarrow.png"
                alt=""
              />
            </a>
          </div>
          <div className="client-text-image">
            <img
              src="https://redcubenew.netlify.app/images/box2.png"
              alt=""
              className="other-client-image"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <p className="link_other_client">Explore Redcube “Career”</p>
            <a href="#link">
              <img
                href="#"
                src="https://redcubenew.netlify.app/images/btnarrow.png"
                alt=""
              />
            </a>
          </div>
          <div className="client-text-image">
            <img
              src="https://redcubenew.netlify.app/images/box3.png"
              alt=""
              className="other-client-image"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <p className="link_other_client">
              <span>Explore Redcube “Career”</span>
            </p>
            <a href="#link">
              <img
                src="https://redcubenew.netlify.app/images/btnarrow.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatOurClientSay;
