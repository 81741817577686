import React from "react";
import "./teams.css";

function Teams() {
  return (
    <>
      <section className="teams">
        <h1 className="red_cube_strategies">Red Cube Strategists </h1>
        <div className="team_members">
          <div className="member_card">
            <img
              className="member_images"
              src="https://redcubenew.netlify.app/images/ajay-prakash.jpg"
              alt=""
            />
            <h5>Ajay Prakash</h5>
            <h6>CEO</h6>
            <p>Over 20+ years of experience in Digital World</p>
          </div>

          <div className="member_card">
            <img
              className="member_images"
              src="https://redcubenew.netlify.app/images/mallika-pande.jpg"
              alt=""
            />
            <h5>Mallika Pande</h5>
            <h6>Director</h6>
            <p>Over 15+ years of experience in Digital World</p>
          </div>

          <div className="member_card">
            <img
              className="member_images"
              src="https://redcubenew.netlify.app/images/loren-howard.jpg"
              alt=""
            />
            <h5>Loren Howard</h5>
            <h6>Director Sales (US)</h6>
            <p>Over 14+ years of experience in Digital World</p>
          </div>

          <div className="member_card">
            <img
              className="member_images"
              src="https://redcubenew.netlify.app/images/puja-khanna.jpg"
              alt=""
            />
            <h5>Puja Khanna</h5>
            <h6>Creative Head</h6>
            <p>Over 15+ years of experience in Digital World</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Teams;
