import "./App.css";
import Footer from "./Components/Footer/Footer";
import MainContent from "./Components/MainContent/MainContent";
import Header from "./Components/Navbar/Navbar";
// import Navbar from "./Components/Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
