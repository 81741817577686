import React from "react";
import "./whyUs.css";

function WhyUs() {
  return (
    <>
      <section className="why_us">
        <h2 className="why_us_heading">Why us</h2>
        <div className="WhyusBox">
          <div className="accordion">
            <div className="accordion-item ">
              <div className="accordion-header">We are boutique</div>
            </div>
            <div className="accordion-item ">
              <div className="accordion-header">
                We create value &amp; deliver results
              </div>
            </div>
            <div className="accordion-item ">
              <div className="accordion-header">
                We think design and feel strategy
              </div>
            </div>
            <div className="accordion-item ">
              <div className="accordion-header">
                Blend of generalists &amp; specialists
              </div>
            </div>
            <div className="accordion-item ">
              <div className="accordion-header">We focus on what you need</div>
            </div>
            <div className="accordion-item ">
              <div className="accordion-header">For brand of all sizes </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyUs;
