// // import React from "react";
// // import "./footer.css";

// // function Footer() {
// //   return (
// //     <div>
// //       <div className="footer">
// //         <div className="newsletter">
// //           <h2>Subscribe to our Newsletter</h2>
// //           <div className="news-input"></div>
// //           <form action="">
// //             <input type="email" name="" id="" placeholder="Enter your email" />
// //             <button className="submit_arrow_button" type="submit">
// //               <img
// //                 className="arrowSubmitButton"
// //                 src="https://redcubenew.netlify.app/images/submit.png"
// //                 alt=""
// //               />
// //             </button>
// //           </form>
// //         </div>

// //         <div className="navigations_1">
// //           <a href="#link1" className="navigation-links">
// //             About us
// //           </a>
// //           <a href="#link2" className="navigation-links">
// //             life@redcubedigital
// //           </a>
// //           <a href="#link3" className="navigation-links">
// //             Career
// //           </a>
// //           <a href="#link4" className="navigation-links">
// //             How we work
// //           </a>
// //           <a href="#link5" className="navigation-links">
// //             We are Hiring
// //           </a>
// //           <a href="#link6" className="navigation-links">
// //             Let's Talk
// //           </a>
// //           <a href="#link7" className="navigation-links">
// //             Blog
// //           </a>
// //         </div>
// //         <div className="navigations_2">
// //           <a href="#link8" className="navigation-links">
// //             Services
// //           </a>
// //           <a href="#link9" className="navigation-links">
// //             Social
// //           </a>
// //           <a href="#link10" className="navigation-links">
// //             Online Ads
// //           </a>
// //           <a href="#link11" className="navigation-links">
// //             Animated Video
// //           </a>
// //           <a href="#link12" className="navigation-links">
// //             SEO
// //           </a>
// //           <a href="#link13" className="navigation-links">
// //             Web Design
// //           </a>
// //         </div>
// //         <div className="address">
// //           <p>Redcube Digital Media Pvt. Ltd</p>
// //           <p>B-102,104 First Floor</p>
// //           <p>Panchsheel Vihar, Sheikh Sarai Phase-1 </p>
// //           <p>New Delhi</p>

// //           <div className="call_sec">
// //             <img
// //               src="https://redcubenew.netlify.app/images/callicon.png"
// //               alt=""
// //             />
// //             <span>For Business Enquiries </span> <br />
// //             <a href="#link">+91858883241 </a>
// //           </div>

// //           <div className="hr_sec">
// //             <img src="https://redcubenew.netlify.app/images/hr.png" alt="" />
// //             <span>HR Related Queries </span> <br />
// //             <a href="#link">+01148083152 </a>
// //           </div>
// //         </div>
// //       </div>
// //       <div className="whatsapp">
// //         <a href="#whatsapp">
// //           <button className="whatsapp_button">Whatsapp</button>
// //         </a>
// //         <h4 className="whatsapp_text">
// //           Get Social Conected{" "}
// //           <span>
// //             <p style={{ fontSize: "16px" }}>&copy; 2024 All rights reserved.</p>
// //           </span>
// //         </h4>
// //       </div>
// //     </div>
// //   );
// // }

// // export default Footer;

// import React from "react";
// import "./footer.css";

// function Footer() {
//   return (
//     <div>
//       <div className="footer">
//         <div className="newsletter">
//           <h2>Subscribe to our Newsletter</h2>
//           <div className="news-input">
//             <form action="">
//               <input
//                 type="email"
//                 name=""
//                 id=""
//                 placeholder="Enter your email"
//               />
//               <button className="submit_arrow_button" type="submit">
//                 <img
//                   className="arrowSubmitButton"
//                   src="https://redcubenew.netlify.app/images/submit.png"
//                   alt=""
//                 />
//               </button>
//             </form>
//           </div>
//         </div>

//         <div className="navigations">
//           <div className="navigation-column">
//             <a href="#link1" className="navigation-links">
//               About us
//             </a>
//             <a href="#link2" className="navigation-links">
//               life@redcubedigital
//             </a>
//             <a href="#link3" className="navigation-links">
//               Career
//             </a>
//             <a href="#link4" className="navigation-links">
//               How we work
//             </a>
//           </div>
//           <div className="navigation-column">
//             <a href="#link5" className="navigation-links">
//               We are Hiring
//             </a>
//             <a href="#link6" className="navigation-links">
//               Let's Talk
//             </a>
//             <a href="#link7" className="navigation-links">
//               Blog
//             </a>
//           </div>
//           <div className="navigation-column">
//             <a href="#link8" className="navigation-links">
//               Services
//             </a>
//             <a href="#link9" className="navigation-links">
//               Social
//             </a>
//             <a href="#link10" className="navigation-links">
//               Online Ads
//             </a>
//           </div>
//           <div className="navigation-column">
//             <a href="#link11" className="navigation-links">
//               Animated Video
//             </a>
//             <a href="#link12" className="navigation-links">
//               SEO
//             </a>
//             <a href="#link13" className="navigation-links">
//               Web Design
//             </a>
//           </div>
//         </div>

//         <div className="address">
//           <p>Redcube Digital Media Pvt. Ltd</p>
//           <p>B-102,104 First Floor</p>
//           <p>Panchsheel Vihar, Sheikh Sarai Phase-1 </p>
//           <p>New Delhi</p>

//           <div className="call_sec">
//             <img
//               src="https://redcubenew.netlify.app/images/callicon.png"
//               alt=""
//             />
//             <span>For Business Enquiries </span> <br />
//             <a href="#link">+91858883241 </a>
//           </div>

//           <div className="hr_sec">
//             <img src="https://redcubenew.netlify.app/images/hr.png" alt="" />
//             <span>HR Related Queries </span> <br />
//             <a href="#link">+01148083152 </a>
//           </div>
//         </div>
//       </div>
//       <div className="whatsapp">
//         <a href="#whatsapp">
//           <button className="whatsapp_button">Whatsapp</button>
//         </a>
//         <h4 className="whatsapp_text">
//           Get Social Connected{" "}
//           <span>
//             <p style={{ fontSize: "16px" }}>&copy; 2024 All rights reserved.</p>
//           </span>
//         </h4>
//       </div>
//     </div>
//   );
// }

// export default Footer;

// import React from "react";
// import "./footer.css";

// function Footer() {
//   return (
//     <div>
//       <div className="footer container-fluid">
//         <div className="row">
//           <div className="col-md-6">
//             <div className="newsletter">
//               <h2>Subscribe to our Newsletter</h2>
//               <form action="" className="d-flex">
//                 <input
//                   type="email"
//                   name=""
//                   id=""
//                   placeholder="Enter your email"
//                   className="form-control email-box"
//                 />
//                 <button className="submit_arrow_button btn" type="submit">
//                   <img
//                     className="arrowSubmitButton"
//                     src="https://redcubenew.netlify.app/images/submit.png"
//                     alt=""
//                   />
//                 </button>
//               </form>
//             </div>
//           </div>

//           <div className="col-md-4">
//             <div className="navigations_1">
//               {/* <h2>Navigation</h2> */}
//               <ul className="list-unstyled">
//                 <li>
//                   <a href="#link1" className="navigation-links">
//                     About us
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link2" className="navigation-links">
//                     life@redcubedigital
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link3" className="navigation-links">
//                     Career
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link4" className="navigation-links">
//                     How we work
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link5" className="navigation-links">
//                     We are Hiring
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link6" className="navigation-links">
//                     Let's Talk
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link7" className="navigation-links">
//                     Blog
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>

//           <div className="col-md-2">
//             <div className="navigations_2">
//               {/* <h2>Services</h2> */}
//               <ul className="list-unstyled">
//                 <li>
//                   <a href="#link8" className="navigation-links">
//                     Services
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link9" className="navigation-links">
//                     Social
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link10" className="navigation-links">
//                     Online Ads
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link11" className="navigation-links">
//                     Animated Video
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link12" className="navigation-links">
//                     SEO
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#link13" className="navigation-links">
//                     Web Design
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>

//         <div className="address">
//           {/* <h2>Contact Us</h2> */}
//           <p>Redcube Digital Media Pvt. Ltd</p>
//           <p>B-102,104 First Floor</p>
//           <p>Panchsheel Vihar, Sheikh Sarai Phase-1 </p>
//           <p>New Delhi</p>

//           <div className="call_sec">
//             <img
//               src="https://redcubenew.netlify.app/images/callicon.png"
//               alt=""
//             />
//             <span>For Business Enquiries </span> <br />
//             <a href="#link">+91858883241 </a>
//           </div>

//           <div className="hr_sec">
//             <img src="https://redcubenew.netlify.app/images/hr.png" alt="" />
//             <span>HR Related Queries </span> <br />
//             <a href="#link">+01148083152 </a>
//           </div>
//         </div>
//       </div>
//       <div className="whatsapp text-center">
//         <a href="#whatsapp">
//           <button className="whatsapp_button btn btn-outline-success">
//             Whatsapp
//           </button>
//         </a>
//         <h4 className="whatsapp_text">
//           Get Social Connected <span>&copy; 2024 All rights reserved.</span>
//         </h4>
//       </div>
//     </div>
//   );
// }

// export default Footer;

import React from "react";
import "./footer.css";

function Footer() {
  return (
    <>
      <section className="footer">
        <div className="container footer_items">
          <div className="upper_footer_items">
            <div className="newsletter">
              <h3>Subscribe to our Newsletter</h3>
              <input
                type="email"
                name=""
                id=""
                placeholder="Enter your email"
              />
              <button
                className="submit_arrow_button btn"
                type="submit"
              ></button>
            </div>
            <div className="navigation_links1">
              <ul>
                <li className="navigation_link_items">
                  <a href="#">About us</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">life@redcubedigital</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Career</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">How we work</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">We are Hiring</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Let's Talk</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div>
            <div className="navigation_links2">
              <ul>
                <li className="navigation_link_items">
                  <a href="#">Services</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Social</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Online Ads</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Animated Video</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">SEO</a>
                </li>
                <li className="navigation_link_items">
                  <a href="#">Web Design</a>
                </li>
              </ul>
            </div>
            <div className="address">
              <p>Redcube Digital Media Pvt. Ltd</p>
              <p>B-102,104 First Floor</p>
              <p>Panchsheel Vihar, Sheikh Sarai Phase-1 </p>
              <p>New Delhi</p>
              <div className="call_sec">
                <img
                  src="https://redcubenew.netlify.app/images/callicon.png"
                  alt=""
                />{" "}
                &nbsp;
                <span>For Business Enquiries </span> <br />
                <a href="#link">+91858883241 </a>
              </div>
              <br />
              <div className="hr_sec">
                <img
                  src="https://redcubenew.netlify.app/images/hr.png"
                  alt=""
                />{" "}
                &nbsp;
                <span>HR Related Queries </span> <br />
                <a href="#link">+01148083152 </a>
              </div>
            </div>
          </div>
          <div className="lower_footer_items">
            <div className="whatsapp">
              <button className="btn btn-outline-success">Whatsapp</button>
            </div>
            <div className="get_social_connected">
              <h4>Get Social Connected</h4>{" "}
              <span>&copy; 2024 All rights reserved.</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
