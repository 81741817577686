import React from "react";
import "./about.css";

function About() {
  return (
    <>
      <section className="about-image-text">
        <img
          className="about-image"
          src="https://redcubenew.netlify.app/static/media/creative.bf0f37c02e8dc2d5e340.png"
          alt=""
        />
        <p className="about">ABOUT</p>
        <h2 className="about-subHeading">
          Embrace the World of Digital Stories!
        </h2>
        <h6 className="about-para">
          Redcube has challenged the boundaries since its inception. Being the
          early player that squeezed in disruptive technologies into clients'
          business objectives to much delight has had cascading effect for all
          involved.
        </h6>
        <button className="more_about_us">More About Us</button>
      </section>
    </>
  );
}

export default About;
