// import React from "react";
// import "./clients.css";

// function Clients() {
//   return (
//     <>
//       <div className="clients-button">
//         <div className="clients">
//           <img
//             src="https://redcubenew.netlify.app/images/client1.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client2.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client3.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client4.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client5.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client6.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client7.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client8.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client9.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client10.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client11.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client12.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client13.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client14.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client15.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client16.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client17.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client18.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client19.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client20.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client21.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client22.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client23.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client24.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client25.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client26.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client27.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client28.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client29.png"
//             alt=""
//             className="client-images"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client30.png"
//             alt=""
//             className="client-images"
//           />
//           {/* <img
//             src="https://redcubenew.netlify.app/images/client29.png"
//             alt=""
//             className="client-images1"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client30.png"
//             alt=""
//             className="client-images1 "
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client29.png"
//             alt=""
//             className="client-images1"
//           />
//           <img
//             src="https://redcubenew.netlify.app/images/client30.png"
//             alt=""
//             className="client-images1 "
//           /> */}
//         </div>
//         <button className="more_clients">more clients</button>
//       </div>
//     </>
//   );
// }

// export default Clients;

import React from "react";
import "./clients.css";

function Clients() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="clients">
            <img
              src="https://redcubenew.netlify.app/images/client1.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client2.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client3.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client4.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client5.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client6.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client7.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client8.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client9.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client10.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client11.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client12.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client13.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client14.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client15.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client16.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client17.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client18.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client19.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client20.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client21.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client22.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client23.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client24.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client25.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client26.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client27.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client28.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client29.png"
              alt=""
              className="client-images"
            />
            <img
              src="https://redcubenew.netlify.app/images/client30.png"
              alt=""
              className="client-images"
            />
            {/* <img
            src="https://redcubenew.netlify.app/images/client29.png"
            alt=""
            className="client-images1"
          />
          <img
            src="https://redcubenew.netlify.app/images/client30.png"
            alt=""
            className="client-images1 "
          />
          <img
            src="https://redcubenew.netlify.app/images/client29.png"
            alt=""
            className="client-images1"
          />
          <img
            src="https://redcubenew.netlify.app/images/client30.png"
            alt=""
            className="client-images1 "
          /> */}
          </div>
          <div className="text-center mt-3">
            <button className="more_clients">more clients</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
