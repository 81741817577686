import React from "react";
import "./getInTouch.css";

function GetInTouch() {
  return (
    <>
      <section className="get_in_touch">
        <h3 className="so_you_have_a_project">So, you have a project. </h3>
        <h3 className="we_can_take_it_to_another_level">
          We can take it to another level.
        </h3>
        <h4>Schedule a meeting with us.</h4>
        <button className="btn_getInTouch">Get in Touch</button>
      </section>
    </>
  );
}

export default GetInTouch;
